import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const BgPlayer = ({ id, source }) => {
  const playerIns = useRef(null);
  const [isUserInteracted, setUserInteracted] = useState(false);

  const handleUserInteraction = () => {
    setUserInteracted(true);

    // Unmute the video on user interaction
    // Unmute the video on user interaction
    if (playerIns.current) {
      playerIns.current.muted(false);

      // Use promise-based playback if supported
      if (typeof playerIns.current.play === "function") {
        playerIns.current
          .play()
          .then(() => {
            // Playback started successfully
          })
          .catch((error) => {
            console.error("Error initiating playback:", error);
          });
      }
    }
  };

  useEffect(() => {
    if (playerIns.current !== null) return () => {};

    playerIns.current = videojs(
      id,
      {
        controls: false,
        autoplay: true,
        fluid: true,
        muted: true,
      },
      () => {
        playerIns.current.src(source);

        // Attach click event directly to the video element
        const videoElement = playerIns.current.el();
        if (videoElement) {
          videoElement.addEventListener("click", handleUserInteraction);
        }
      }
    );

    return () => {
      if (playerIns.current) {
        const videoElement = playerIns.current.el();
        if (videoElement) {
          videoElement.removeEventListener("click", handleUserInteraction);
        }
        playerIns.current.dispose();
      }
    };
  }, [id, source]);

  return (
    <div className="video-container home-page-video" id="video-container">
      <div id="bg-video-player">
        {id && (
          <video
            id={id}
            className="video-js vjs-default-skin"
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  );
};

BgPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default BgPlayer;

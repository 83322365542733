/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import LandingPageData from "../../static/landingPage";
import MainLayout from "../../layout/Main";
import HomePageBgPlayer from "../../components/common/HomePageBgPlayer";

import image1 from "../../assets/images/footer-logos/image01.png";
// import image2 from "../../assets/images/footer-logos/image02.png";
import image3 from "../../assets/images/footer-logos/image3.png";
// import image4 from "../../assets/images/footer-logos/image4.jpg";
// import image5 from "../../assets/images/footer-logos/image5.png";

import dceNetworkLogo from "../../assets/images/about_us_section_imagery/dce_network.png";

const LandingPage = ({
  menuData,
  activePage,
  activePageLayout,
  handlePageChange,
  landingPageButton,
}) => {
  const [activeDropDown, setActiveDropDown] = useState();
  const [toggle, setToggle] = useState(false);

  const openDropDown = (id) => {
    setActiveDropDown(id);
    setToggle(!toggle);
  };

  return (
    <div>
      <MainLayout
        menuData={menuData}
        activePage={activePage}
        handlePageChange={handlePageChange}
        activePageLayoutType={activePageLayout.layout}
        copyRightText={LandingPageData.copyRight}
      >
        <div className="landing-page-main-container">
          <div className="landing-page-main">
            <div className="background-live-stream">
              <HomePageBgPlayer
                id="bg-player"
                source={process.env.REACT_APP_CHANNEL_WEBSITE_DATA}
              />
            </div>
            <div className="landing-page-container">
              {/* {LandingPageData.topSection.heading && (
              <h1>{LandingPageData.topSection.heading}</h1>
            )}
            {LandingPageData.topSection.sub_heading && (
              <p>{LandingPageData.topSection.sub_heading}</p>
            )}
            {LandingPageData.topSection.description && (
              <h3>{LandingPageData.topSection.description}</h3>
            )}
            {LandingPageData.topSection.button_text && (
              <button
                className="landing-page-explore-btn"
                type="button"
                onClick={() => landingPageButton()}
              >
                {LandingPageData.topSection.button_text}
              </button>
            )} */}
            </div>
          </div>
        </div>
      </MainLayout>

      <div className="watch-video">
        <h2>DCE Network</h2>
        <h5>Watch Free Anytime, Anywhere, on Any Device!</h5>
        <p>The District of Columbia’s Entertainment Network</p>
        <a href="/" className="btn" onClick={() => landingPageButton()}>
          Watch Now
        </a>
      </div>

      <div className="Footer-Box">
        <div className="content">
          Download Any of Our Apps And Watch{" "}
          <span className="footer_text_span">FREE</span> Content
        </div>
        <div className="content-end">
          <div className="icons_sec">
            {/* <ul>
              <li>
                <a href="#" target="_blank">
                  <img src={image5} alt="" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <img src={image4} alt="" />
                </a>
              </li>
            </ul> */}
            <ul>
              <li>
                <a
                  href="https://channelstore.roku.com/details/891aedd1b19fa553046be0bb0188f6b9/dce"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={image1} alt="" />
                </a>
              </li>
              {/* <li>
                <a href="https://apps.apple.com/us/app/dce-tv/id1539425194#?platform=appleTV">
                  <img src={image2} alt="" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://www.amazon.com/gp/product/B08MLCQW6J"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={image3} alt="" />
                </a>
              </li>
            </ul>
          </div>
          <div className="dce_network_logo">
            <img src={dceNetworkLogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
LandingPage.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  activePage: PropTypes.string.isRequired,
  activePageLayout: PropTypes.shape({
    layout: PropTypes.string,
    bgVideo: PropTypes.string,
  }).isRequired,
  handlePageChange: PropTypes.func.isRequired,
  landingPageButton: PropTypes.func.isRequired,
};

export default LandingPage;

/* eslint-disable import/no-duplicates */
// import sectionOneImage from '../assets/images/about_us_section_imagery/section1.jpg';
import sectionTwoImage from '../assets/images/about_us_section_imagery/section2.png';
import sectionThreeImage from '../assets/images/about_us_section_imagery/section3.png';
import sectionFourImage from '../assets/images/about_us_section_imagery/section4.png';
import sectionFiveImage from '../assets/images/about_us_section_imagery/section5.png';
import sectionSixImage from '../assets/images/about_us_section_imagery/section6.jpg';
import dceNetworkLogo from '../assets/images/about_us_section_imagery/dce_network_menu.jpg';
import aboutUsBg from '../assets/images/about_us_bg.png';

const LandingPageData = {
  topSection: {
    heading: 'DCE Network',
    image: dceNetworkLogo,
    bg_img: aboutUsBg,
  },

  // faq: {
  //   main_title: 'Frequently Asked Questions',
  //   question_answer: [
  //     {
  //       question: 'What is VONtv?',
  //       answer:`VONtv is a free streaming service powered by the legacy of WVON Radio, offering a diverse mix of live programs, films, TV series, and specials that embody the spirit and culture of Black Chicago.`,
  //     },
  //     {
  //       question: 'How do I access VONtv?',
  //       answer:`You can watch VONtv for FREE on various devices, including Roku, Amazon Fire TV, Apple TV, tablets, computers, and mobile phones. Just download the VON TV app or visit our website to start streaming.`
  //     },
  //     {
  //       question: 'Where can I watch VONtv?',
  //       answer:
  //         `VONtv is available for free streaming on a wide range of devices. Enjoy our content on your laptop, Roku TV, Apple TV, Amazon Fire TV, or through our mobile app on iOS and Android devices.`,
  //     },
  //     {
  //       question: 'What can I watch on VONtv?',
  //       answer:
  //         `VONtv offers original live programs, on-demand films, TV series, special entertainment events, and more, all reflecting the rich African American culture and spirit of Chicago.`,
  //     },
  //     {
  //       question: 'Is VONtv suitable for everyone?',
  //       answer:
  //         `Absolutely! VONtv provides culturally relevant and empowering content for a broad audience. Our programming is designed to be inclusive, enlightening, and entertaining for everyone.`,
  //     },
  //   ],
  // },


  sections: {
    section_details: [
      // {
      //   heading: 'Culturally Relevant Content',
      //   description: `ARTISTS IN RESIDENCE
      //     CAMBIOS - SPANISH SPEAKING EPISODIC SERIES
      //     CRUSHING THE GAME
      //     DCE PRESENTS - DOCUMENTARIES AND SPECIALS
      //     DKN SPORTS
      //     FIELD PASS SPORTS
      //     GOOD THINGS DC - HEALTH AND LIFESTYLE
      //     HIDDEN GEMS - TRAVEL DESTINATIONS
      //     I WISH YOU KNEW
      //     LIL BRUCE GAMER - GAME REVIEWS AND MORE!
      //     MET FEST PROMOS
      //     Q & A CAFE - CANDID CONVERSATIONS WITH CAROL JOYNT
      //     SALUTE TO OUR MILITARY
      //     SPEAK OUT DC - YOUTH DISCUSSION
      //     STRAIGHT CRANKIN - DIVE INTO THE HISTORY OF GOGO
      //     THE 202 - LIFESTYLE
      //     THE ROAD TO REPRESENTATION
      //     THE SOUND - WHERE DC'S HOTTEST ARTISTS COMPETE
      //     UNION PILOT - COMEDY SITCOM
      //     URBAN BEAUTY CON - FASHION AND BEAUTY
      //     WE ARE WASHINGTON
      //     WHAT'S REALLY GOING DOWN? - LIFESTYLE AND ENTERTAINMENT`,
      //   image: sectionOneImage,
      //   link: "#"
      // },
      {
        heading: 'Specials and Documentaries',
        description: "Experience the vibrancy of Washington, DC with our special entertainment and live events. From live concerts to cultural festivals, DCE Network brings the capital’s dynamic events to your screen. DCE covers live political debates and produces insightful panel discussions that offer a mix of empowering dialogue and thought-provoking topics that resonate.",
        image: sectionTwoImage,
        link: "#"
      },
      {
        heading: 'Sports',
        description: `DCE is the viewing place for local sport fans.`,
        image: sectionThreeImage,
        link: "#"
      },
      {
        heading: 'Live Stream - Watch Now',
        description: `Watch DCE Live Streams Now!`,
        image: sectionFourImage,
        link: "#"
      },
      {
        heading: `What's Going On DC?`,
        description: `Watch DCE orginial talk shows to learn what is going on in DC.`,
        image: sectionFiveImage,
        link: "#"
      },
      {
        heading: 'Musical Vibes',
        description: `DCE brings DC musical artists and entertainment straight to your favorite listen device.`,
        image: sectionSixImage,
        link: "#"
      },
    ]
  },

  about_us: {
    heading: 'About Us',
    description: `The District of Columbia’s Entertainment Network (DCE) delivers FREE lifestyle, public affairs, live events, sports, arts, and entertainment programming from the nation’s capital. The channel showcases the creative prowess of those who call the District of Columbia their home. Watch original award-winning entertainment and educational programs produced in Washington, DC for a global audience.`,
  },
  copyRight: 'Copyright © 2023 All rights reserved',
};

export default LandingPageData;
